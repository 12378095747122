import {
    Abstract
} from './Abstract';

import axios from "axios";

export class FiscalResidence extends Abstract {
    constructor(session) {
        super('fiscal_residence/FiscalResidence', session);
        this.street = null;
        this.number_ext = null;
        this.number_int = null;
        this.postal_code = null;
        this.suburb = null;
        this.municipality = null;
        this.location = null;
        this.reference = null;
        this.state_sat_code = null;
        this.country_sat_code = null;
        this.rfc = null;
        this.suburb_name = null;
        this.state_name = null;
        this.municipality_name = null;
        this.location_name = null;
    }

    async postalcode(params) {
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        
        let response = await axios.get(this.controller + "/postalcode" + query, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }
}
