import {
    Abstract
} from './Abstract';

export class ConsignmentNoteTrailer extends Abstract {
    constructor(session) {
        super('consignment_note_trailer/ConsignmentNoteTrailer', session);
        this.id_number = null;
        this.id_consignment_note = null;
        this.trailer_code = null;
        this.license_plate = null;

        //* Campos adicionales */
        this.trailer_name = null;
    }
}
