import {
    Abstract
} from './Abstract';

export class ConsignmentNoteGoods extends Abstract {
    constructor(session) {
        super('consignment_note_goods/ConsignmentNoteGoods', session);
        this.id_number = null;
        this.id_consignment_note = null;
        this.imported_goods_sat_code = null;
        this.description = null;
        this.amount = 0.00;
        this.unit_sat_code = null;
        this.unit_name = null;
        this.weight_kg = 0.00;
        this.value_goods = 0.00;
        this.currency = null;
        this.dangerous_material = null;
        this.dangerous_material_sat_code = null;
        this.packing_type_sat_code = null;
        this.tariff_fraction = null;
        this.uuid_trade_ext = null;
        this.identification_guide = null;
        this.petitions = 1;
        this.petition_number = [''];

    }
}
