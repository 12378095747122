import {
    Abstract
} from './Abstract';

export class ConsignmentNoteFigure extends Abstract {
    constructor(session) {
        super('consignment_note_figure/ConsignmentNoteFigure', session);
        this.figure_type_code = null;
        this.rfc = null;
        this.name = null;
        this.license_number = null;
        this.tributary_id = null;
        this.tax_residence = null;
    }
}
