import {
    Abstract
} from './Abstract';

export class ConsignmentNoteOriginLocation extends Abstract {
    constructor(session) {
        super('consignment_note_origin_location/ConsignmentNoteOriginLocation', session);
        this.id_number = null;
        this.sender_rfc = null;
        this.sender_name = null;
        this.departure_date = null;
        this.season_type = null;
        this.id_fiscal_residence = null;
        this.id_consignment_note = null;
        this.id_location = null;
    }
}
