import {
    Abstract
} from './Abstract';

export class ConsignmentNoteTypesFigure extends Abstract {
    constructor(session) {
        super('consignment_note_types_figure/ConsignmentNoteTypesFigure', session);
        this.id_number = null;
        this.id_figure = null;
        this.id_consignment_note = null;
        this.id_fiscal_residence = null;
        this.part_figure = null;

        /* Campos adicionales */
        this.figure_name = null;
        this.figure_rfc = null;
        this.figure_licence = null;
    }
}
