import {
    Abstract
} from './Abstract';

export class ConsignmentNoteDestinationLocation extends Abstract {
    constructor(session) {
        super('consignment_note_destination_location/ConsignmentNoteDestinationLocation', session);
        this.id_number = null;
        this.addressee_rfc = null;
        this.addressee_name = null;
        this.arrival_date = null;
        this.distance_traveled = 0.00;
        this.season_type = null;
        this.id_fiscal_residence = null;
        this.id_consignment_note = null;
        this.id_location = null;
    }
}
