<template>
    <div class="card">
        <Loader v-model="loading" />
        <Helper v-model="consignmentHelper" header="Buscar Carta Porte" :headers="cartaPorteHeaders" :rows="consignments" @selected="selectConsignment" />
        <Helper v-model="customerHelper" header="Buscar Cliente" :headers="customersHeaders" :rows="customers" @selected="selectCustomer" />
        <Helper v-model="subTrailerHelper" header="Buscar Remolque" :headers="subTrailerHeaders" :rows="subTrailers" @selected="selectSubTrailer" />
        <Helper v-model="subTrailerHelperUpdate" header="Actualizar Remolque" :headers="subTrailerHeaders" :rows="subTrailers" @selected="selectSubTrailerUpdate" />
        <Helper v-model="residenceFiscalOriginHelper" header="Buscar Domicilio Fiscal" :headers="residencesFiscalHeaders" :rows="residencesFiscalOrigin" @selected="selectResidenceFiscalOrigin" />
        <Helper v-model="residenceFiscalOriginHelperUpdate" header="Actualizar Domicilio Fiscal" :headers="residencesFiscalHeaders" :rows="residencesFiscalOrigin" @selected="selectResidenceFiscalOriginUpdate" />
        <Helper v-model="residenceFiscalDestinyHelper" header="Buscar Domicilio Fiscal" :headers="residencesFiscalHeaders" :rows="residencesFiscalDestiny" @selected="selectResidenceFiscalDestiny" />
        <Helper v-model="residenceFiscalDestinyHelperUpdate" header="Actualizar Domicilio Fiscal" :headers="residencesFiscalHeaders" :rows="residencesFiscalDestiny" @selected="selectResidenceFiscalDestinyUpdate" />
        <Helper v-model="residenceFiscalFigureHelper" header="Buscar Domicilio Fiscal" :headers="residencesFiscalHeaders" :rows="residencesFiscalFigure" @selected="selectResidenceFiscalFigure" />
        <Helper v-model="residenceFiscalFigureHelperUpdate" header="Actualizar Domicilio Fiscal" :headers="residencesFiscalHeaders" :rows="residencesFiscalFigure" @selected="selectResidenceFiscalFigureUpdate" />
        <Helper v-model="figureHelper" header="Buscar Figura" :headers="figureHeaders" :rows="figures" @selected="selectFigure" />
        <Helper v-model="figureHelperUpdate" header="Actualizar Figura" :headers="figureHeaders" :rows="figures" @selected="selectFigureUpdate" />
        <Helper v-model="confVehicularHelper" header="Buscar Configuracion" :headers="confVehicularHeaders" :rows="confvehicles" @selected="selectVehicle" />
        <Helper :style="{ width: '50%' }" @filter="findProdServCP" :lazy="true" v-model="prodServHelperCP" header="Buscar Bien Transportado / Servicio SAT" :headers="prodServCPHeaders" :rows="prodServsCP" @selected="selectProdServCP">
            <template v-slot:filters>
                <div class="col-6">
                    <label for="">ID</label>
                    <InputText v-model.trim="prodServHelperCP.id" />
                </div>
                <div class="col-6">
                    <label for="">Descripcion</label>
                    <InputText v-model.trim="prodServHelperCP.descripcion" />
                </div>
            </template>
        </Helper>
        <Helper :style="{ width: '50%' }" @filter="findProdServCP" :lazy="true" v-model="prodServHelperCPUpdate" header="Actualizar Bien Transportado / Servicio SAT" :headers="prodServCPHeaders" :rows="prodServsCP" @selected="selectProdServCPUpdate">
            <template v-slot:filters>
                <div class="col-6">
                    <label for="">ID</label>
                    <InputText v-model.trim="prodServHelperCPUpdate.id" />
                </div>
                <div class="col-6">
                    <label for="">Descripcion</label>
                    <InputText v-model.trim="prodServHelperCPUpdate.descripcion" />
                </div>
            </template>
        </Helper>
        <Helper :style="{ width: '50%' }" @filter="findFractionTariff" :lazy="true" v-model="fractionTariffHelper" header="Buscar Fracción Arancelaria / Servicio SAT" :headers="fractionTariffHeaders" :rows="fractionTariffs" @selected="selectFractionTariff">
            <template v-slot:filters>
                <div class="col-6">
                    <label for="">ID</label>
                    <InputText v-model.trim="fractionTariffHelper.id" />
                </div>
                <div class="col-6">
                    <label for="">Descripcion</label>
                    <InputText v-model.trim="fractionTariffHelper.descripcion" />
                </div>
            </template>
        </Helper>
        <Helper :style="{ width: '50%' }" @filter="findFractionTariff" :lazy="true" v-model="fractionTariffHelperUpdate" header="Actualizar Fracción Arancelaria / Servicio SAT" :headers="fractionTariffHeaders" :rows="fractionTariffs" @selected="selectFractionTariffUpdate">
            <template v-slot:filters>
                <div class="col-6">
                    <label for="">ID</label>
                    <InputText v-model.trim="fractionTariffHelper.id" />
                </div>
                <div class="col-6">
                    <label for="">Descripcion</label>
                    <InputText v-model.trim="fractionTariffHelper.descripcion" />
                </div>
            </template>
        </Helper>
        <Helper :style="{ width: '50%' }" @filter="findMaterialPeligroso" :lazy="true" v-model="materialPeligrosoHelper" header="Buscar Material Peligroso / Servicio SAT" :headers="materialPeligrosoHeaders" :rows="materialPeligrosos" @selected="selectMaterialPeligroso">
            <template v-slot:filters>
                <div class="col-6">
                    <label for="">ID</label>
                    <InputText v-model.trim="materialPeligrosoHelper.id" />
                </div>
                <div class="col-6">
                    <label for="">Descripcion</label>
                    <InputText v-model.trim="materialPeligrosoHelper.descripcion" />
                </div>
            </template>
        </Helper>
        <Helper :style="{ width: '50%' }" @filter="findTipoEmbalaje" :lazy="true" v-model="tipoEmbalajeHelper" header="Buscar Tipo Embalaje / Servicio SAT" :headers="tipoEmbalajeHeaders" :rows="tiposEmbalajes" @selected="selectTipoEmbalaje">
            <template v-slot:filters>
                <div class="col-6">
                    <label for="">ID</label>
                    <InputText v-model.trim="tipoEmbalajeHelper.id" />
                </div>
                <div class="col-6">
                    <label for="">Descripcion</label>
                    <InputText v-model.trim="tipoEmbalajeHelper.descripcion" />
                </div>
            </template>
        </Helper>
        <Helper :style="{ width: '50%' }" @filter="findMaterialPeligroso" :lazy="true" v-model="materialPeligrosoHelperUpdate" header="Actualizar Material Peligroso / Servicio SAT" :headers="materialPeligrosoHeaders" :rows="materialPeligrosos" @selected="selectMaterialPeligrosoUpdate">
            <template v-slot:filters>
                <div class="col-6">
                    <label for="">ID</label>
                    <InputText v-model.trim="materialPeligrosoHelper.id" />
                </div>
                <div class="col-6">
                    <label for="">Descripcion</label>
                    <InputText v-model.trim="materialPeligrosoHelper.descripcion" />
                </div>
            </template>
        </Helper>
         <Helper :style="{ width: '50%' }" @filter="findTipoEmbalaje" :lazy="true" v-model="tipoEmbalajeHelperUpdate" header="Actualizar Tipo Embalaje / Servicio SAT" :headers="tipoEmbalajeHeaders" :rows="tiposEmbalajes" @selected="selectTipoEmbalajeUpdate">
            <template v-slot:filters>
                <div class="col-6">
                    <label for="">ID</label>
                    <InputText v-model.trim="tipoEmbalajeHelper.id" />
                </div>
                <div class="col-6">
                    <label for="">Descripcion</label>
                    <InputText v-model.trim="tipoEmbalajeHelper.descripcion" />
                </div>
            </template>
        </Helper>
         <Dialog :style="{ width: '75%' }" :closeOnEscape="true" :closable="true" :modal="true" header="Modificar Remolque" v-model:visible="subTrailerDialog">
             <div class="p-fluid formgrid grid">
                            <FormInputText wrapperClass="field col-2" label="Clave" v-model="subTrailerUpdate.trailer_code" :search="true" @search="subTrailerHelperUpdate.visible = true"/> 
                            <FormInputText wrapperClass="field col-4" label="Nombre" v-model="subTrailerUpdate.trailer_name"/> 
                            <FormInputText wrapperClass="field col-2" label="Licencia" v-model="subTrailerUpdate.license_plate"/> 
                            <div class="field col-12">
                                <Button @click="actualizaSubRemolque" icon="pi pi-save" label="Actualiza" />
                            </div>
           </div>
        </Dialog>
        <Dialog :style="{ width: '75%' }" :closeOnEscape="true" :closable="true" :modal="true" header="Modificar Ubicacion Origen" v-model:visible="originLocationDialog">
              <div class="p-fluid formgrid grid">
                            <FormInputText  wrapperClass="field col-2" label="RFC Remitente" v-model="originLocationUpdate.sender_rfc" :valid="validate_origin_location_update.validations.sender_rfc" />
                            <FormInputText  wrapperClass="field col-2" label="Nombre Remitente" v-model="originLocationUpdate.sender_name" :valid="validate_origin_location_update.validations.sender_name" />
                           <div class="field col-2">
                                <label>Domicilio Fiscal</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="originLocationUpdate.id_fiscal_residence" />
                                    <Button @click="residenceFiscalOriginHelperUpdate.visible = true" icon="pi pi-search" class="p-button-primary" />
                                </div>
                            </div>
                            <div class="field col-2">
                                Fecha / Hora Salida 
                              <Calendar id="time24" v-model="originLocationUpdate.departure_date" :showTime="true" :showSeconds="true" />
                              <small class="p-invalid" v-if="validate_origin_location_update.validations.departure_date === false"> Debe seleccionar la hora de salida. </small>
                            </div>
                            <FormInputText  wrapperClass="field col-2" label="ID Ubicación" v-model="originLocationUpdate.id_location" :valid="validate_origin_location_update.validations.id_location" />
                            <div class="field col-12">
                                <Button @click="actualizaSaveOriginLocation" icon="pi pi-save" label="Actualizar" />
                            </div>
                       </div>
        </Dialog>
        <Dialog :style="{ width: '75%' }" :closeOnEscape="true" :closable="true" :modal="true" header="Modificar Ubicacion Destino" v-model:visible="destinyLocationDialog">
              <div class="p-fluid formgrid grid">
                            <FormInputText  wrapperClass="field col-2" label="RFC Remitente" v-model="destinyLocationUpdate.addressee_rfc" :valid="validate_destiny_location_update.validations.addressee_rfc" />
                            <FormInputText  wrapperClass="field col-2" label="Nombre Remitente" v-model="destinyLocationUpdate.addressee_name" :valid="validate_destiny_location_update.validations.addressee_name" />
                          <div class="field col-2">
                                <label>Domicilio Fiscal</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="destinyLocationUpdate.id_fiscal_residence" />
                                    <Button @click="residenceFiscalDestinyHelperUpdate.visible = true" icon="pi pi-search" class="p-button-primary" />
                                </div>
                            </div>
                             <div class="field col-2">
                                Fecha / Hora Llegada 
                              <Calendar id="time24" v-model="destinyLocationUpdate.arrival_date" :showTime="true" :showSeconds="true" />
                              <small class="p-invalid" v-if="validate_destiny_location_update.validations.arrival_date === false"> Debe seleccionar la hora de llegada. </small>
                            </div>
                            <FormInputNumber  wrapperClass="field col-2" label="Distancia Recorrida" v-model="destinyLocationUpdate.distance_traveled" :valid="validate_destiny_location_update.validations.distance_traveled" />
                            <FormInputText  wrapperClass="field col-2" label="ID Ubicación" v-model="destinyLocationUpdate.id_location" :valid="validate_destiny_location_update.validations.id_location" />
                            <div class="field col-12">
                                <Button @click="actualizaSaveDestinyLocation" icon="pi pi-save" label="Actualizar" />
                            </div>
                       </div>
        </Dialog>
        <Dialog :style="{ width: '75%' }" :closeOnEscape="true" :closable="true" :modal="true" header="Modificar Tipos de Figura" v-model:visible="typesFigureDialog">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-1">
                                <label>Figura</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="typesFigureUpdate.id_figure" />
                                    <Button @click="figureHelperUpdate.visible = true" icon="pi pi-search" class="p-button-primary" />
                                </div>
                            </div>
                            <FormInputText  wrapperClass="field col-3" label="RFC" v-model="typesFigureUpdate.figure_rfc" :valid="validate_types_figure_update.validations.figure_rfc" :disabled="true" />
                            <FormInputText  wrapperClass="field col-4" label="Nombre" v-model="typesFigureUpdate.figure_name" :disabled="true" />
                            <FormInputText  wrapperClass="field col-2" label="Licencia" v-model="typesFigureUpdate.figure_licence" :disabled="true" />
                            <div class="field col-2">
                                <label>Domicilio Fiscal</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="typesFigureUpdate.id_fiscal_residence" />
                                    <Button @click="residenceFiscalFigureHelperUpdate.visible = true" icon="pi pi-search" class="p-button-primary" />
                                </div>
                            </div>
                               <div v-if="typesFigure.id_figure == '02' || typesFigure.id_figure == '03'" class="field col-2">
                                <label for="sat">Parte Transporte</label>
                                <Dropdown :filter="true" v-model="typesFigureUpdate.part_figure" :options="c_ParteTransporte" optionLabel="Descripcion" optionValue="c_ParteTransporte" :class="{ 'p-invalid': validate_types_figure_update.validations.part_figure === false }">
                                    <template #option="slotProps">
                                        <div>
                                            <div>{{ slotProps.option.c_ParteTransporte }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                        </div>
                                    </template>
                                </Dropdown>
                            <small class="p-invalid" v-if="validate_types_figure_update.validations.part_figure === false"> Favor de llenar el campo </small>
                            </div>
                            <div class="field col-12">
                                <Button @click="actualizaSaveTypesFigure" icon="pi pi-save" label="Actualizar" />
                            </div>  
                </div> 
        </Dialog>
          <Dialog :style="{ width: '75%' }" :closeOnEscape="true" :closable="true" :modal="true" header="Modificar Mercancia" v-model:visible="goodsDialog">
                        <div class="p-fluid formgrid grid">
                            <FormInputText  wrapperClass="field col-2" label="Bienes Transportados" v-model="goodsUpdate.imported_goods_sat_code" :valid="validate_goods_update.validations.imported_goods_sat_code" :search="true" @search="prodServHelperCPUpdate.visible = true"   />
                            <FormInputText  wrapperClass="field col-4" label="Descripcion" v-model="goodsUpdate.description" />
                            <FormInputText v-if="entity.international_transport == '1'" wrapperClass="field col-2" label="Fracción Arancelaria" v-model="goodsUpdate.tariff_fraction" :valid="validate_goods_update.validations.tariff_fraction" :search="true" @search="fractionTariffHelperUpdate.visible = true"   />
                            <div class="field col-2">
                                <label for="sat">Unidad Aduana</label>
                                <Dropdown :filter="true" v-model="goodsUpdate.unit_sat_code" :options="c_UnidadAduana" optionLabel="Descripcion" optionValue="c_UnidadAduana" :class="{ 'p-invalid': validate_goods_update.validations.unit_sat_code === false }">
                                    <template #option="slotProps">
                                        <div>
                                            <div>{{ slotProps.option.c_UnidadAduana }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                        </div>
                                    </template>
                                </Dropdown>
                            <small class="p-invalid" v-if="validate_goods_update.validations.unit_sat_code === false"> Favor de llenar el campo </small>
                            </div>
                            <FormInputNumber wrapperClass="field col-2" :minFractionDigits="2" :maxFractionDigits="8" label="Cantidad" v-model="goodsUpdate.amount" :valid="validate_goods_update.validations.amount " /> 
                            <FormInputText v-if="goodsUpdate.dangerous_material == '1' || goodsUpdate.dangerous_material == '0,1'" wrapperClass="field col-2" label="Material Peligroso" v-model="goodsUpdate.dangerous_material_sat_code" :valid="validate_goods_update.validations.tariff_fraction" :search="true" @search="materialPeligrosoHelperUpdate.visible = true"   />
                            <FormInputText v-if="goodsUpdate.dangerous_material == '1' || goodsUpdate.dangerous_material == '0,1'" wrapperClass="field col-2" label="Tipo Embalaje" v-model="goodsUpdate.packing_type_sat_code" :valid="validate_goods_update.validations.packing_type_sat_code" :search="true" @search="tipoEmbalajeHelperUpdate.visible = true"   />
                            <FormInputNumber wrapperClass="field col-2" :minFractionDigits="2" :maxFractionDigits="8" label="Peso KG" v-model="goodsUpdate.weight_kg" :valid="validate_goods_update.validations.weight_kg " /> 
                            <FormInputNumber wrapperClass="field col-2" :minFractionDigits="2" :maxFractionDigits="8" label="Valor Mercancia" v-model="goodsUpdate.value_goods" :valid="validate_goods_update.validations.value_goods " /> 
                            <div class="field col-2">
                                <label for="sat">Moneda</label>
                                <Dropdown :filter="true" v-model="goodsUpdate.currency" :options="c_Moneda" optionLabel="c_Moneda" optionValue="c_Moneda" :class="{ 'p-invalid': validate_goods_update.validations.currency === false }">
                                        <template #option="slotProps">
                                            <div>
                                                <div>{{ slotProps.option.c_Moneda }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                            </div>
                                        </template>
                                </Dropdown>
                            </div>
                             <div class="p-fluid formgrid grid" v-if="entity.international_transport == '1'">
                               <FormInputNumber :showStepper="true" v-model="goodsUpdate.petitions" :wrapperClass="'col-2 field'" label="# de Pedimentos" :min="1" :max="5" :minFractionDigits="0" :maxFractionDigits="0" />
                               <InputMask  v-for="petition in goodsUpdate.petitions" mask="99  99  9999  9999999" placeholder="99  99  9999  9999999" :key="'petition_' + petition" v-model="goodsUpdate.petition_number[petition - 1]" :wrapperClass="'col-2 field'" :label="'No. Pedimento #'+ petition"/>
                            </div> 
                            <div class="field col-12">
                                <Button @click="actualizaSaveGoods" icon="pi pi-save" label="Guardar" />
                            </div>
                </div>
        </Dialog>
<Toast />
        <Panel header="Carta Porte">
             <BasicFormToolbar :actions="['new','save']" @new="openNew" @save="save">
            </BasicFormToolbar>
            <div class="grid">
                <div class="col-12">
                    <Fieldset :toggleable="true" :collapsed="20" legend="Informacion General">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-2">
                             <label>Folio</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="entity.id" />
                                    <Button @click="consignmentHelper.visible = true" icon="pi pi-search" class="p-button-primary" />
                                </div>
                            </div>
                            <div class="field col-2">
                                <label for="sat">Clave Transporte</label>
                                <Dropdown :filter="true" v-model="entity.transport_sat_code" :options="c_CveTransporte" optionLabel="Descripcion" optionValue="c_CveTransporte" :class="{ 'p-invalid': validate.validations.transport_sat_code === false }">
                                    <template #option="slotProps">
                                        <div>
                                            <div>{{ slotProps.option.c_CveTransporte }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                        </div>
                                    </template>
                                </Dropdown>
                            <small class="p-invalid" v-if="validate.validations.transport_sat_code === false"> Favor de llenar el campo </small>
                            </div>
                            <FormInputNumber label="Total Mercancia"  wrapperClass="field col-2" :valid="validate.validations.goods_number" v-model="entity.goods_number" />
                            <FormInputText label="ID Vehicular"  wrapperClass="field col-2" v-model="entity.id_consignment_note_vehicle_control" @search="(confVehicularHelper.visible = true)" :search="true" :tooltip="'Dar de alta en la esta ruta: Catalogos/CFDI 4.0/Control Vehicular'"/>
                            <div class="field col-2">
                                <label>Conf. Vehicular</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="entity.vehicle_config_sat_code" />
                                </div>
                            </div>
                            <div class="field col-2">
                                <label>Cliente</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="entity.id_customer" />
                                    <Button @click="customerHelper.visible = true" icon="pi pi-search" class="p-button-primary" />
                                </div>
                            </div>
                            <div class="field col-2">
                                <FormDropdown :options="internationaltransport" v-model="entity.international_transport" :optionLabel="'text'" :optionValue="'id'"  label="Internacional" />
                            </div>
                            <div v-if="entity.international_transport == '1'" class="field col-2">
                              <FormDropdown :options="entryexitgood" v-model="entity.entry_exit_good" :optionLabel="'text'" :optionValue="'id'" label="Vía" />
                           </div>
                           <div v-if="entity.international_transport == '1'" class="field col-2">
                                <label for="sat">Vía Entrada/Salida</label>
                                <Dropdown :filter="true" v-model="entity.via_input_output" :options="c_CveTransporte" optionLabel="Descripcion" optionValue="c_CveTransporte" :class="{ 'p-invalid': validate.validations.via_input_output === false }">
                                    <template #option="slotProps">
                                        <div>
                                            <div>{{ slotProps.option.c_CveTransporte }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                        </div>
                                    </template>
                                </Dropdown>
                            <small class="p-invalid" v-if="validate.validations.via_input_output === false"> Favor de llenar el campo </small>
                            </div>
                            <div v-if="entity.international_transport == '1'" class="field col-2">
                                <label for="sat">Pais Destino</label>
                                <Dropdown :filter="true" v-model="entity.destination_country_sat_code" :options="c_Pais" optionLabel="Descripcion" optionValue="c_Pais" :class="{ 'p-invalid': validate.validations.destination_country_sat_code === false }">
                                    <template #option="slotProps">
                                        <div>
                                            <div>{{ slotProps.option.c_Pais }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                        </div>
                                    </template>
                                </Dropdown>
                            <small class="p-invalid" v-if="validate.validations.destination_country_sat_code === false"> Favor de llenar el campo </small>
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <br />
                <FadeInOut entry="left" exit="right" :duration="350" mode="out-in">
                     <div v-if="this.entity.sub_trailer == '1' || this.entity.sub_trailer == '0, 1'" class="col-12">
                    <Fieldset :toggleable="true" :collapsed="20" legend="Sub Remolques">
                        <div class="p-fluid formgrid grid">
                            <FormInputText wrapperClass="field col-2" label="Clave" v-model="subTrailer.trailer_code" :search="true" @search="subTrailerHelper.visible = true"/> 
                            <FormInputText wrapperClass="field col-4" label="Nombre" v-model="subTrailer.trailer_name"/> 
                            <FormInputText wrapperClass="field col-2" label="Licencia" v-model="subTrailer.license_plate"/> 
                            <div class="field col-12">
                                <Button @click="saveSubRemolque" icon="pi pi-save" label="Guardar" />
                            </div>
                            <div class="field col-12">
                                <BasicDatatable 
                                @selected="selectTrailer" 
                                :headers="subRemolqueHeaders" 
                                :rows="entity.consignment_note_trailers"
                                :rowaction="true" :rowdelete="true"  @deleted="confirmDeleteSubTrailer" />
                            </div>
                            <DeleteDialogComponent v-model="deleteDialog" @deleted="deleteSubTrailer" @closed="deleteDialog = false" />
                       </div>
                    </Fieldset>
                </div>
                </FadeInOut>
                <div class="col-12">
                    <Fieldset :toggleable="true" :collapsed="20" legend="Ubicación Origen">
                        <div class="p-fluid formgrid grid">
                            <FormInputText  wrapperClass="field col-2" label="RFC Remitente" v-model="originLocation.sender_rfc" :valid="validate_origin_location.validations.sender_rfc" />
                            <FormInputText  wrapperClass="field col-2" label="Nombre Remitente" v-model="originLocation.sender_name" :valid="validate_origin_location.validations.sender_name" />
                            <FormInputText wrapperClass="field col-2" label="Domicilio Fiscal" v-model="originLocation.id_fiscal_residence" @search="(residenceFiscalOriginHelper.visible = true)" :search="true" :tooltip="'Dar de alta en esta ruta: Catalogos/CFDI 4.0/Domicilio Fiscal'"/>
                            <div class="field col-2">
                                Fecha / Hora Salida 
                              <Calendar id="time24" v-model="originLocation.departure_date" :showTime="true" :showSeconds="true" />
                              <small class="p-invalid" v-if="validate_origin_location.validations.departure_date === false"> Debe seleccionar la hora de salida. </small>
                            </div>
                            <FormInputText  wrapperClass="field col-2" label="ID Ubicación" v-model="originLocation.id_location" :valid="validate_origin_location.validations.id_location" :tooltip="'Llenar campo solo si se tienen dos o más Ubicaciones Origen'" />
                            <div class="field col-12">
                                <Button @click="saveOriginLocation" icon="pi pi-save" label="Guardar" />
                            </div>
                            <div class="field col-12">
                                <BasicDatatable 
                                @selected="selectOriginLocation" 
                                :headers="originLocationsHeaders" 
                                :rows="entity.consignment_note_origin_locations"
                                :rowaction="true" :rowdelete="true"  @deleted="confirmDeleteOrigin" />
                            </div>
                            <DeleteDialogComponent v-model="deleteDialog" @deleted="deleteOriginLocation" @closed="deleteDialog = false" />
                       </div>
                    </Fieldset>
                </div>
                 <div class="col-12">
                    <Fieldset :toggleable="true" :collapsed="20" legend="Ubicación Destino">
                        <div class="p-fluid formgrid grid">
                            <FormInputText  wrapperClass="field col-2" label="RFC Destinatario" v-model="destinyLocation.addressee_rfc" :valid="validate_destiny_location.validations.addressee_rfc" />
                            <FormInputText  wrapperClass="field col-2" label="Nombre Destinatario" v-model="destinyLocation.addressee_name" :valid="validate_destiny_location.validations.addressee_name" />
                            <FormInputText wrapperClass="field col-2" label="Domicilio Fiscal" v-model="destinyLocation.id_fiscal_residence" @search="(residenceFiscalOriginHelper.visible = true)" :search="true" :tooltip="'Dar de alta en esta ruta: Catalogos/CFDI 4.0/Domicilio Fiscal'"/>
                            <div class="field col-2">
                                Fecha / Hora Llegada 
                              <Calendar id="time24" v-model="destinyLocation.arrival_date" :showTime="true" :showSeconds="true" />
                              <small class="p-invalid" v-if="validate_destiny_location.validations.arrival_date === false"> Debe seleccionar la hora de llegada. </small>
                            </div>
                            <FormInputNumber  wrapperClass="field col-2" label="Distancia Recorrida" v-model="destinyLocation.distance_traveled" :valid="validate_destiny_location.validations.distance_traveled" />
                            <FormInputText  wrapperClass="field col-2" label="ID Ubicación" v-model="destinyLocation.id_location" :valid="validate_destiny_location.validations.id_location" :tooltip="'Llenar campo solo si se tienen dos o más Ubicaciones Destino'"/>
                            <div class="field col-12">
                                <Button @click="saveDestinyLocation" icon="pi pi-save" label="Guardar" />
                            </div>
                            <div class="field col-12">
                                <BasicDatatable 
                                @selected="selectDestinyLocation" 
                                :headers="destinyLocationsHeaders" 
                                :rows="entity.consignment_note_destiny_locations"
                                :rowaction="true" :rowdelete="true"  @deleted="confirmDeleteDestiny" />
                            </div>
                            <DeleteDialogComponent v-model="deleteDialog" @deleted="deleteDestinyLocation" @closed="deleteDialog = false" />
                       </div>
                    </Fieldset>
                </div>
                  <div class="col-12">
                    <Fieldset :toggleable="true" :collapsed="20" legend="Tipos Figura">
                        <div class="p-fluid formgrid grid">
                            <FormInputText wrapperClass="field col-1" label="Figura" v-model="typesFigure.id_figure" @search="(figureHelper.visible = true)" :search="true" :tooltip="'Dar de alta en esta ruta: Catalogos/CFDI 4.0/Figura'"/>
                            <FormInputText  wrapperClass="field col-3" label="RFC" v-model="typesFigure.figure_rfc" :valid="validate_types_figure.validations.figure_rfc" :disabled="true" />
                            <FormInputText  wrapperClass="field col-4" label="Nombre" v-model="typesFigure.figure_name" :disabled="true" />
                            <FormInputText  wrapperClass="field col-2" label="Licencia" v-model="typesFigure.figure_licence" :disabled="true" />
                            <FormInputText wrapperClass="field col-2" label="Domicilio Fiscal" v-model="typesFigure.id_fiscal_residence" @search="(residenceFiscalOriginHelper.visible = true)" :search="true" :tooltip="'Dar de alta en esta ruta: Catalogos/CFDI 4.0/Domicilio Fiscal'"/>
                               <div v-if="typesFigure.id_figure == '02' || typesFigure.id_figure == '03'" class="field col-2">
                                <label for="sat">Parte Transporte</label>
                                <Dropdown :filter="true" v-model="typesFigure.part_figure" :options="c_ParteTransporte" optionLabel="Descripcion" optionValue="c_ParteTransporte" :class="{ 'p-invalid': validate_types_figure.validations.part_figure === false }">
                                    <template #option="slotProps">
                                        <div>
                                            <div>{{ slotProps.option.c_ParteTransporte }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                        </div>
                                    </template>
                                </Dropdown>
                            <small class="p-invalid" v-if="validate_types_figure.validations.part_figure === false"> Favor de llenar el campo </small>
                            </div>
                            <div class="field col-12">
                                <Button @click="saveTypesFigure" icon="pi pi-save" label="Guardar" />
                            </div>
                            <div class="field col-12">
                                <BasicDatatable 
                                @selected="selectTypesFigure" 
                                :headers="typesFigureHeaders" 
                                :rows="entity.consignment_note_types_figures"
                                :rowaction="true" :rowdelete="true"  @deleted="confirmDeleteTypesFigure" />
                            </div>
                            <DeleteDialogComponent v-model="deleteDialog" @deleted="deleteTypesFigure" @closed="deleteDialog = false" />
                       </div>
                    </Fieldset>
                </div>
                <div class="col-12">
                    <Fieldset :toggleable="true" :collapsed="20" legend="Mercancias">
                        <div class="p-fluid formgrid grid">
                            <FormInputText  wrapperClass="field col-2" label="Bienes Transportados" v-model="goods.imported_goods_sat_code" :valid="validate_goods.validations.imported_goods_sat_code" :search="true" @search="prodServHelperCP.visible = true"   />
                            <FormInputText  wrapperClass="field col-4" label="Descripcion" v-model="goods.description" />
                            <FormInputText v-if="entity.international_transport == '1'" wrapperClass="field col-2" label="Fracción Arancelaria" v-model="goods.tariff_fraction" :valid="validate_goods.validations.tariff_fraction" :search="true" @search="fractionTariffHelper.visible = true"   />
                            <div class="field col-2">
                                <label for="sat">Unidad Aduana</label>
                                <Dropdown :filter="true" v-model="goods.unit_sat_code" :options="c_UnidadAduana" optionLabel="Descripcion" optionValue="c_UnidadAduana" :class="{ 'p-invalid': validate_goods.validations.unit_sat_code === false }">
                                    <template #option="slotProps">
                                        <div>
                                            <div>{{ slotProps.option.c_UnidadAduana }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                        </div>
                                    </template>
                                </Dropdown>
                            <small class="p-invalid" v-if="validate_goods.validations.unit_sat_code === false"> Favor de llenar el campo </small>
                            </div>
                            <FormInputNumber wrapperClass="field col-2" :minFractionDigits="2" :maxFractionDigits="8" label="Cantidad" v-model="goods.amount" :valid="validate_goods.validations.amount " /> 
                            <FormInputText v-if="goods.dangerous_material == '1' || goods.dangerous_material == '0,1'" wrapperClass="field col-2" label="Material Peligroso" v-model="goods.dangerous_material_sat_code" :valid="validate_goods.validations.tariff_fraction" :search="true" @search="materialPeligrosoHelper.visible = true"   />
                            <FormInputText v-if="goods.dangerous_material == '1' || goods.dangerous_material == '0,1'" wrapperClass="field col-2" label="Tipo Embalaje" v-model="goods.packing_type_sat_code" :valid="validate_goods.validations.packing_type_sat_code" :search="true" @search="tipoEmbalajeHelper.visible = true"   />
                            <FormInputNumber wrapperClass="field col-2" :minFractionDigits="2" :maxFractionDigits="8" label="Peso KG" v-model="goods.weight_kg" :valid="validate_goods.validations.weight_kg " /> 
                            <FormInputNumber wrapperClass="field col-2" :minFractionDigits="2" :maxFractionDigits="8" label="Valor Mercancia" v-model="goods.value_goods" :valid="validate_goods.validations.value_goods " /> 
                            <div class="field col-2">
                                <label for="sat">Moneda</label>
                                <Dropdown :filter="true" v-model="goods.currency" :options="c_Moneda" optionLabel="c_Moneda" optionValue="c_Moneda" :class="{ 'p-invalid': validate_goods.validations.currency === false }">
                                        <template #option="slotProps">
                                            <div>
                                                <div>{{ slotProps.option.c_Moneda }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                            </div>
                                        </template>
                                </Dropdown>
                            </div>
                            <div class="p-fluid formgrid grid" v-if="entity.international_transport == '1'">
                                <FormInputNumber  :showStepper="true" v-model="goods.petitions" :wrapperClass="'col-3 field'" label="# de Pedimentos" :min="0" :max="5" :minFractionDigits="0" :maxFractionDigits="0" />
                                <InputMask  v-for="petition in goods.petitions" mask="99  99  9999  9999999" placeholder="99  99  9999  9999999" :key="'petition_' + petition" v-model="goods.petition_number[petition - 1]" :wrapperClass="'col-1 field'" :label="'No. Pedimento #'+ petition"/>
                            </div> 
                            <div class="field col-12">
                                <Button @click="saveGoods" icon="pi pi-save" label="Guardar" />
                            </div>
                            <div class="field col-12">
                                <BasicDatatable 
                                @selected="selectGoods" 
                                :headers="goodsHeaders" 
                                :rows="entity.consignment_note_goods"
                                :rowaction="true" :rowdelete="true"  @deleted="confirmDeleteGoods" />
                            </div>
                             <DeleteDialogComponent v-model="deleteDialog" @deleted="deleteGoods" @closed="deleteDialog = false" />
                       </div>
                    </Fieldset>
                </div>
            </div>
        </Panel>
    </div>
</template>

<script>
import { Branch } from '../../../models/general/Branch';
import { Rule, fillObject, ErrorToast, satCatalogo, HeaderGrid, validate } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import Helper from '../../../components/general/HelperDialog.vue';

import FormInputText from '../../../components/general/FormInputText.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import Session from '../../../mixins/sessionMixin';
import { Customer } from '../../../models/comercial/Customer';
import { ConsignmentNote } from '../../../models/cfdi40/ConsingmentNote';
import { ConsignmentNoteVehicleControl } from '../../../models/cfdi40/ConsingmentNoteVehicleControl';
import { FiscalResidence } from '../../../models/cfdi40/FiscalResidence';
import FormDropdown from '../../../components/general/FormDropdown.vue'
import { ConsignmentNoteOriginLocation } from '../../../models/cfdi40/ConsingmentNoteOriginLocation';
import { ConsignmentNoteDestinationLocation } from '../../../models/cfdi40/ConsingmentNoteDestinationLocation';
import { ConsignmentNoteTypesFigure } from '../../../models/cfdi40/ConsingmentNoteTypesFigure';
import { ConsignmentNoteFigure } from '../../../models/cfdi40/ConsingmentNoteFigure';
import { ConsignmentNoteGoods } from '../../../models/cfdi40/ConsingmentNoteGoods';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import DeleteDialogComponent from '../../../components/general/DeleteDialog.vue';
import { ConsignmentNoteTrailer } from '../../../models/cfdi40/ConsingmentNoteTrailer';
export default {
    mixins: [Session],
    props: {
        id: null,
        view: null,
    },
    data() {
        return {
            deleteDialog: false,
            newConcept: false,
            entity: null,
            entities: [],
            consignments: [],
            customers: [],
            confvehicles: [],
            subTrailers: [],
            residencesFiscalOrigin: [],
            residencesFiscalDestiny: [],
            residencesFiscalFigure: [],
            subTrailerDialog: false,
            originLocationDialog: false,
            destinyLocationDialog: false,
            typesFigureDialog: false,
            goodsDialog: false,
            figures:[],
            prodServsCP: [],
            fractionTariffs: [],
            materialPeligrosos: [],
            tiposEmbalajes: [],
            c_Moneda: [],
            c_ConfigAutotransporte: [],
            c_SubTipoRem:[],
            cartaPorteHeaders: [
                new HeaderGrid('Folio', 'id'),
                new HeaderGrid('SAT Codigo Transporte', 'transport_sat_code'),
                new HeaderGrid('No. Mercancias', 'goods_number'),
                new HeaderGrid('Folio Control Vehicular', 'id_consignment_note_vehicle_control'),
                new HeaderGrid('Via', 'entry_exit_good'),
                new HeaderGrid('SAT Codigo Vehicular', 'vehicle_config_sat_code'),    
            ],
            customersHeaders: [
                new HeaderGrid('Folio', 'id'),
                new HeaderGrid('Nombre', 'name'),
                new HeaderGrid('Apellidos', 'last_name'),
                new HeaderGrid('RFC', 'rfc'),
                new HeaderGrid('Direccion', 'address'),
                new HeaderGrid('CP', 'postal_code'),
                new HeaderGrid('Pais', 'country'),
                new HeaderGrid('Estado', 'state'),
                new HeaderGrid('Municipio', 'municipality'),
                new HeaderGrid('Colonia', 'suburb'),
                new HeaderGrid('Regimen Fiscal', 'tax_regime'),
            ],
           subTrailerHeaders: [
                new HeaderGrid('Código', 'c_SubTipoRem'),
                new HeaderGrid('Nombre', 'name'),
            ],
            subRemolqueHeaders: [
                new HeaderGrid('Código', 'trailer_code'),
                new HeaderGrid('Licencia', 'license_plate'),
            ],
           residencesFiscalHeaders: [
                new HeaderGrid('Folio', 'id'),
                new HeaderGrid('Calle', 'street'),
                new HeaderGrid('Colonia', 'suburb'),
                new HeaderGrid('Numero Exterior', 'number_ext'),
                new HeaderGrid('Municipio', 'municipality'),
                new HeaderGrid('Codigo Postal', 'postal_code'),
                new HeaderGrid('Estado', 'state_sat_code'),
                new HeaderGrid('Pais', 'country_sat_code'),
                new HeaderGrid('RFC', 'rfc'),
            ],
            originLocationsHeaders: [
                new HeaderGrid('RFC', 'sender_rfc'),
                new HeaderGrid('Nombre', 'sender_name'),
                new HeaderGrid('Fecha/Hora Salida', 'departure_date'),
                new HeaderGrid('Residencia Fiscal', 'id_fiscal_residence'),
                new HeaderGrid('ID Ubicacion', 'id_location'),
            ],
            destinyLocationsHeaders: [
                new HeaderGrid('RFC', 'addressee_rfc'),
                new HeaderGrid('Nombre', 'addressee_name'),
                new HeaderGrid('Fecha/Hora Llegada', 'arrival_date'),
                new HeaderGrid('Residencia Fiscal', 'id_fiscal_residence'),
                new HeaderGrid('Distancia Recorrida', 'distance_traveled'),
                new HeaderGrid('ID Ubicacion', 'id_location'),
            ],
            figureHeaders: [
                new HeaderGrid('Codigo', 'figure_type_code'),
                new HeaderGrid('RFC', 'rfc'),
                new HeaderGrid('Nombre', 'name'),
                new HeaderGrid('Numero de Licencia', 'license_number'),
                new HeaderGrid('ID Tributario', 'tributary_id'),
                new HeaderGrid('Domicilio Fiscal', 'tax_residence'),
            ],
            typesFigureHeaders: [
                new HeaderGrid('Figura', 'id_figure'),
                new HeaderGrid('Nombre', 'figure_name'),
                new HeaderGrid('RFC', 'figure_rfc'),
                new HeaderGrid('Licencia', 'figure_licence'),
                new HeaderGrid('Residencia Fiscal', 'id_fiscal_residence'),
                new HeaderGrid('Parte Figura', 'part_figure'),
                
            ],
             goodsHeaders: [
                new HeaderGrid('Bien Transportado', 'imported_goods_sat_code'),
                new HeaderGrid('Descripcion', 'description'),
                new HeaderGrid('Unidad Aduana', 'unit_sat_code'),
                new HeaderGrid('Cantidad', 'amount'),
                new HeaderGrid('Peso KG', 'weight_kg'),
                new HeaderGrid('Valor Mercancia', 'value_goods'),
                new HeaderGrid('Moneda', 'currency'),
            ],
            confVehicularHeaders: [
                new HeaderGrid('Folio', 'id'), 
                new HeaderGrid('Codigo SAT', 'vehicle_config_sat_code'),
                new HeaderGrid('Licencia', 'license_plate_vm'),
                new HeaderGrid('Anio Modelo', 'anio_vm'),
                new HeaderGrid('Aseguradora Civil', 'anio_vm'),
                new HeaderGrid('Permiso SCT', 'permit_sct_sat')],
            prodServCPHeaders: [new HeaderGrid('ID', 'id'), new HeaderGrid('Descripcion', 'descripcion')],
            fractionTariffHeaders: [new HeaderGrid('ID', 'id'), new HeaderGrid('Descripcion', 'descripcion'), new HeaderGrid('Unidad Medida', 'umt')],
            materialPeligrosoHeaders: [new HeaderGrid('ID', 'id'), new HeaderGrid('Descripcion', 'descripcion')],
            tipoEmbalajeHeaders: [new HeaderGrid('ID', 'id'), new HeaderGrid('Descripcion', 'descripcion')],
            branch: new Branch(this.session),
            originLocation: new ConsignmentNoteOriginLocation(this.session),
            subTrailer: new ConsignmentNoteTrailer(this.session),
            destinyLocation: new ConsignmentNoteDestinationLocation(this.session),
            typesFigure: new ConsignmentNoteTypesFigure(this.session),
            goods: new ConsignmentNoteGoods(this.session),
            originLocationUpdate: new ConsignmentNoteOriginLocation(),
            subTrailerUpdate: new ConsignmentNoteTrailer(),
            destinyLocationUpdate: new ConsignmentNoteDestinationLocation(),
            typesFigureUpdate: new ConsignmentNoteTypesFigure(),
            goodsUpdate: new ConsignmentNoteGoods(),
            c_MetodoPago: [],
            c_CveTransporte: [],
            c_Parteransporte: [],
            c_Pais: [],
            c_UnidadAduana: [],
            internationaltransport: [
                { id: '0', text: 'No' },
                { id: '1', text: 'Sí' }
            ],
            entryexitgood: [
                { id: 'Entrada', text: 'Entrada' },
                { id: 'Salida', text: 'Salida' }
            ],
            rules: [
                new Rule({ name: 'transport_sat_code' }),
                new Rule({ name: 'goods_number' }),
                new Rule({ name: 'id_consignment_note_vehicle_control' }),
                new Rule({ name: 'vehicle_config_sat_code' }),
                new Rule({ name: 'international_transport' }),
                new Rule({ name: 'entry_exit_good' }),
                new Rule({ name: 'via_input_output' }),
                new Rule({ name: 'destination_country_sat_code' }),
            ],
            rules_subTrailer: [
                //* Version avanzada de reglas
                new Rule({ name: 'trailer_code' }),
                new Rule({ name: 'license_plate' }),
            ],
            rules_originLocation: [
                //* Version avanzada de reglas
                new Rule({ name: 'sender_rfc' }),
                new Rule({ name: 'sender_name' }),
                new Rule({ name: 'departure_date' }),
                new Rule({ name: 'id_fiscal_residence' }),
            ],
            rules_destinyLocation: [
                //* Version avanzada de reglas
                new Rule({ name: 'addressee_rfc' }),
                new Rule({ name: 'addressee_name' }),
                new Rule({ name: 'arrival_date' }),
                new Rule({ name: 'id_fiscal_residence' }),
                new Rule({ name: 'distance_traveled' }),
            ],
            rules_typesFigure: [
                //* Version avanzada de reglas
                new Rule({ name: 'id_figure' }),
                new Rule({ name: 'id_fiscal_residence' }),
            ],
             rules_goods: [
                //* Version avanzada de reglas
                new Rule({ name: 'imported_goods_sat_code' }),
                new Rule({ name: 'description' }),
                new Rule({ name: 'unit_sat_code' }),
                new Rule({ name: 'weight_kg' }),
                new Rule({ name: 'value_goods' }),
            ],
            validate: {
                valid: false,
                validations: {
                    transport_sat_code: null,
                    goods_number: null,
                    id_consignment_note_vehicle_control: null,
                    vehicle_config_sat_code: null,
                    international_transport: null,
                    via_input_output: null,
                    entry_exit_good: null,
                    destination_country_sat_code: null,
                },
            },
            validate_origin_location: {
                valid: false,
                validations: {
                    sender_rfc: null,
                    sender_name: null,
                    departure_date: null,
                    id_fiscal_residence: null,
                },
            },
              validate_sub_trailer: {
                valid: false,
                validations: {

                },
            },
             validate_destiny_location: {
                valid: false,
                validations: {
                    addressee_rfc: null,
                    addressee_name: null,
                    arrival_date: null,
                    id_fiscal_residence: null,
                    distance_traveled: null,
                },
            },
             validate_types_figure: {
                valid: false,
                validations: {
                    addressee_rfc: null,
                    addressee_name: null,
                    arrival_date: null,
                    id_fiscal_residence: null,
                    distance_traveled: null,
                },
            },
            validate_goods: {
                valid: false,
                validations: {
                    // imported_goods_sat_code: null,
                    // description: null,
                    // tariff_fraction: null,
                    // amount: null,
                    // unit_sat_code: null,
                    // unit_name: null,
                    // weight_kg: null,
                    // value_goods: null,
                },
            },
            validate_sub_trailer_update: {
                valid: false,
                validations: {
                   
                },
            },
            validate_origin_location_update: {
                valid: false,
                validations: {
                    sender_rfc: null,
                    sender_name: null,
                    departure_date: null,
                    id_fiscal_residence: null,
                },
            },
             validate_destiny_location_update: {
                valid: false,
                validations: {
                    addressee_rfc: null,
                    addressee_name: null,
                    arrival_date: null,
                    id_fiscal_residence: null,
                    distance_traveled: null,
                },
            },
             validate_types_figure_update: {
                valid: false,
                validations: {
                    id_figure: null,
                    id_fiscal_residence: null,
                },
            },
            validate_goods_update: {
                valid: false,
                validations: {
                    imported_goods_sat_code: null,
                    description: null,
                    tariff_fraction: null,
                    amount: null,
                    unit_sat_code: null,
                    unit_name: null,
                    weight_kg: null,
                    value_goods: null,
                },
            },
            loading: false,
            consignmentHelper: {
                visible: false,
            },
            customerHelper: {
                visible: false,
            },
            subTrailerHelper: {
                visible: false,
            },
            subTrailerHelperUpdate: {
                visible: false,
            },
            residenceFiscalOriginHelper: {
                visible: false,
            },
            residenceFiscalOriginHelperUpdate: {
                visible: false,
            },
            residenceFiscalDestinyHelper: {
                visible: false,
            },
             residenceFiscalDestinyHelperUpdate: {
                visible: false,
            },
            residenceFiscalFigureHelper: {
                visible: false,
            },
            residenceFiscalFigureHelperUpdate: {
                visible: false,
            },
            figureHelper: {
                visible: false,
            },
            figureHelperUpdate: {
                visible: false,
            },
            confVehicularHelper: {
                visible: false,
            },
            prodServHelperCP: {
                visible: false,
                id: null,
                descripcion: null,
            },
            prodServHelperCPUpdate: {
                visible: false,
                id: null,
                descripcion: null,
            },
            fractionTariffHelper: {
                visible: false,
                id: null,
                descripcion: null,
                umt: null,
            },
             fractionTariffHelperUpdate: {
                visible: false,
                id: null,
                descripcion: null,
                umt: null,
            },
            materialPeligrosoHelper: {
                visible: false,
                id: null,
                descripcion: null,
            },
            tipoEmbalajeHelper: {
                visible: false,
                id: null,
                descripcion: null,
            },
            materialPeligrosoHelperUpdate: {
                visible: false,
                id: null,
                descripcion: null,
            },
            tipoEmbalajeHelperUpdate: {
                visible: false,
                id: null,
                descripcion: null,
            },
        };
    },
    components: {DeleteDialogComponent, FormInputNumber, BasicFormToolbar, FormDropdown, Loader, Helper, FormInputText, BasicDatatable },
    created() {
        this.entity = new ConsignmentNote(this.session);
    },
    async mounted() {
        await this.refresh();
        this.loading = true;
        //Cargamos la sucursal
        this.branch.id = this.session.branch;
        this.branch = fillObject(this.branch, await this.branch.retrieve());
        //Aqui cargamos los CATALOGOS ESTATICOS
        this.c_MetodoPago = await satCatalogo(3);
        this.c_CveTransporte = await satCatalogo(24);
        this.c_ParteTransporte = await satCatalogo(25);
        this.c_ClaveProdServCP = await satCatalogo(26);
        this.c_Pais = await satCatalogo(11),
        (this.c_UnidadAduana = await satCatalogo(27)),
        this.c_ConfigAutotransporte = await satCatalogo(23),
        this.subTrailers = await satCatalogo(20);
        this.c_Moneda = await satCatalogo(9), (this.loading = false);

    },
    watch: {
         'entity.id_consignment_note_vehicle_control'(newValue){
            if(newValue){  
             this.entity.sub_trailer = this.c_ConfigAutotransporte.find((x) => x.c_ConfigAutotransporte == this.entity.vehicle_config_sat_code).c_SubTipoRem;
            console.log(this.entity.sub_trailer);
            }
        },
        'goods.petitions'(newValue, oldValue) {
            let newStrings = newValue - oldValue;
            if (this.goods.petition_number.length != newValue) {
                if (newStrings >= 0)
                {
                for (let i = 0; i < newStrings; i++) {
                    this.goods.petition_number.push('');
                }
                }else {
                for (let i = 0; i < Math.abs(newStrings); i++) {
                    this.goods.petition_number.pop();
                }
                }
            }
        },
        'goodsUpdate.petitions'(newValue, oldValue) {
            let newStrings = newValue - oldValue;
            if (this.goodsUpdate.petition_number.length != newValue) {
                if (newStrings >= 0)
                {
                for (let i = 0; i < newStrings; i++) {
                    this.goodsUpdate.petition_number.push('');
                }
                }else {
                for (let i = 0; i < Math.abs(newStrings); i++) {
                    this.goodsUpdate.petition_number.pop();
                }
                }
            }
        },
    },
    methods: {
        saas(cosn)
        {
            console.log(cosn);
        },
         async selectConsignment(consignment) {
            try {
                this.loading = true;
                this.entity = fillObject(this.entity, consignment);
                 //* Llenamos los remolques
                 this.entity.consignment_note_trailers = await this.entity.trailers();

                 //* Llenamos las ubicaciones de origen
                 this.entity.consignment_note_origin_locations = await this.entity.origins();

                 //* Llenamos las ubicaciones de destino
                 this.entity.consignment_note_destiny_locations = await this.entity.destinations();
                
                //* Llenamos los tipos de figura
                 this.entity.consignment_note_types_figures = await this.entity.typesFigures();

                 //* Llenamos las mercancias
                 this.entity.consignment_note_goods = await this.entity.goods();

            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
         selectTrailer(payload) {
            payload.update = true;
            this.subTrailerDialog = true;
            this.subTrailerUpdate = payload;
        },
         selectOriginLocation(payload) {
            payload.update = true;
            this.originLocationDialog = true;
            this.originLocationUpdate = payload;
        },
        selectDestinyLocation(payload) {
            payload.update = true;
            this.destinyLocationDialog = true;
            this.destinyLocationUpdate = payload;
        },
        selectTypesFigure(payload) {
            payload.update = true;
            this.typesFigureDialog = true;
            this.typesFigureUpdate = payload;
        },
        selectGoods(payload) {
            payload.update = true;
            this.goodsDialog = true;
            this.goodsUpdate = payload;
        },
        selectCustomer(customer) {
            this.entity.id_customer = customer.id; 
        },
        selectVehicle(confVehicle) {
            this.entity.id_consignment_note_vehicle_control = confVehicle.id;
            this.entity.vehicle_config_sat_code = confVehicle.vehicle_config_sat_code;
        },
        selectResidenceFiscalOrigin(residenceFiscalOrigin) {
            this.originLocation.id_fiscal_residence = residenceFiscalOrigin.id; 
        },
        selectSubTrailer(subTrailer) {
            this.subTrailer.trailer_code = subTrailer.c_SubTipoRem; 
            this.subTrailer.trailer_name = subTrailer.name;
        },
        selectSubTrailerUpdate(subTrailer) {
            this.subTrailerUpdate.trailer_code = subTrailer.c_SubTipoRem; 
            this.subTrailerUpdate.trailer_name = subTrailer.name;
        },
        selectResidenceFiscalOriginUpdate(residenceFiscalOrigin) {
            this.originLocationUpdate.id_fiscal_residence = residenceFiscalOrigin.id; 
        },
        selectResidenceFiscalDestiny(residenceFiscalDestiny) {
            this.destinyLocation.id_fiscal_residence = residenceFiscalDestiny.id; 
        },
        selectResidenceFiscalDestinyUpdate(residenceFiscalDestiny) {
            this.destinyLocationUpdate.id_fiscal_residence = residenceFiscalDestiny.id; 
        },
        selectResidenceFiscalFigure(residenceFiscalFigure) {
            this.typesFigure.id_fiscal_residence = residenceFiscalFigure.id; 
        },
        selectResidenceFiscalFigureUpdate(residenceFiscalFigure) {
            this.typesFigureUpdate.id_fiscal_residence = residenceFiscalFigure.id; 
        },
        selectFigure(figures) {
            this.typesFigure.id_figure = figures.id; 
            this.typesFigure.figure_name = figures.name;
            this.typesFigure.figure_rfc = figures.rfc;
            this.typesFigure.figure_licence = figures.license_number;
        },
        selectFigureUpdate(figures) {
            this.typesFigureUpdate.id_figure = figures.id; 
            this.typesFigureUpdate.figure_name = figures.name;
            this.typesFigureUpdate.figure_rfc = figures.rfc;
            this.typesFigureUpdate.figure_licence = figures.license_number;
        },
        selectProdServCP(prodServCP) {
            this.goods.imported_goods_sat_code = prodServCP.id;
            this.goods.description = prodServCP.descripcion;
            this.goods.dangerous_material = prodServCP.material_peligroso;
            console.log(prodServCP.material_peligroso);
        },
        selectProdServCPUpdate(prodServCP) {
            this.goodsUpdate.imported_goods_sat_code = prodServCP.id;
            this.goodsUpdate.description = prodServCP.descripcion;
            this.goodsUpdate.dangerous_material = prodServCP.material_peligroso;
        },
        selectFractionTariff(fractionTariff) {
            this.goods.tariff_fraction = fractionTariff.id;
            this.goods.unit_sat_code = fractionTariff.umt;
        },
        selectFractionTariffUpdate(fractionTariff) {
            this.goodsUpdate.tariff_fraction = fractionTariff.id;
            this.goodsUpdate.unit_sat_code = fractionTariff.umt;
        },
        selectMaterialPeligroso(materialPeligroso) {
            this.goods.dangerous_material_sat_code = materialPeligroso.id;
        },
        selectTipoEmbalaje(tipoEmbalaje) {
            this.goods.packing_type_sat_code = tipoEmbalaje.id;
        },
        selectMaterialPeligrosoUpdate(materialPeligroso) {
            this.goodsUpdate.dangerous_material_sat_code = materialPeligroso.id;
        },
        selectTipoEmbalajeUpdate(tipoEmbalaje) {
            this.goodsUpdate.packing_type_sat_code = tipoEmbalaje.id;
        },
        async findProdServCP() {
            try {
                this.loading = true;
                this.prodServsCP = await this.entity.prodservCP({
                    id: this.prodServHelperCP.id,
                    descripcion: this.prodServHelperCP.descripcion,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async findFractionTariff() {
            try {
                this.loading = true;
                this.fractionTariffs = await this.entity.fractiontariff({
                    id: this.fractionTariffHelper.id,
                    descripcion: this.fractionTariffHelper.descripcion,
                    umt: this.fractionTariffHelper.umt,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
         async findMaterialPeligroso() {
            try {
                this.loading = true;
                this.materialPeligrosos = await this.entity.materialpeligroso({
                    id: this.materialPeligrosoHelper.id,
                    descripcion: this.materialPeligrosoHelper.descripcion,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
         async findTipoEmbalaje() {
            try {
                this.loading = true;
                this.tiposEmbalajes = await this.entity.tipoembalaje({
                    id: this.tipoEmbalajeHelper.id,
                    descripcion: this.tipoEmbalajeHelper.descripcion,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        hideDialog() {
            this.newDialog = false;
        },
        async save() {
            try {
              //* Validacion de form
                this.loading = true;
                if (this.entity.consignment_note_trailers.length == 0 && (this.entity.sub_trailer == '1' || this.entity.sub_trailer == '0, 1')) throw 'No se capturaron remolques.';
                if (this.entity.consignment_note_types_figures.length == 0) throw 'No se capturaron tipos de figura.';
                if (this.entity.consignment_note_origin_locations.length == 0) throw 'No se capturaron ubicaciones origen.';
                if (this.entity.consignment_note_destiny_locations.length == 0) throw 'No se capturaron ubicaciones destino.';
                if (this.entity.consignment_note_goods.length == 0) throw 'No se capturaron mercancias.';
                
                if (this.entity.id && this.entity.id > 0) 
                {       
                       let response = await this.entity.update({
                        id: this.entity.id,
                    });
                    this.entity.id = response.id;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Actualizar',
                        detail: 'Informacion actualizada con exito',
                        life: 3000,
                    });
                }
                else
                {
                    let response = await this.entity.post();
                    this.entity.id = response.id;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Guardar',
                        detail: 'Informacion guardada con exito',
                        life: 3000,
                    });
                }

            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        saveSubRemolque() {
            try {
               
                this.validate_sub_trailer = validate(this.subTrailer, this.rules_subTrailer);
                if (!this.validate_sub_trailer.valid) throw 'Favor de validar los campos';

                let subTrailer = {
                    ...this.subTrailer,
                };

                subTrailer.id_number = (this.entity.consignment_note_trailers.length + 1).toString();
                this.entity.consignment_note_trailers.push(subTrailer);
                    this.subTrailer = new ConsignmentNoteTrailer(this.session);
                } catch (error) {
                    this.$toast.add(new ErrorToast(error));
                }
        },
         saveOriginLocation() {
            try {
               
                this.validate_origin_location = validate(this.originLocation, this.rules_originLocation);
                if (!this.validate_origin_location.valid) throw 'Favor de validar los campos';

                let originLocation = {
                    ...this.originLocation,
                };

                originLocation.id_number = (this.entity.consignment_note_origin_locations.length + 1).toString();
                this.entity.consignment_note_origin_locations.push(originLocation);
                    this.originLocation = new ConsignmentNoteOriginLocation(this.session);
                } catch (error) {
                    this.$toast.add(new ErrorToast(error));
                }
        },
        saveDestinyLocation() {
            try {
               
                this.validate_destiny_location = validate(this.destinyLocation, this.rules_destinyLocation);
                if (!this.validate_destiny_location.valid) throw 'Favor de validar los campos';

                let destinyLocation = {
                    ...this.destinyLocation,
                };

                destinyLocation.id_number = (this.entity.consignment_note_destiny_locations.length + 1).toString();
                this.entity.consignment_note_destiny_locations.push(destinyLocation);
                    this.destinyLocation = new ConsignmentNoteDestinationLocation(this.session);
                } catch (error) {
                    this.$toast.add(new ErrorToast(error));
                }
        },
        saveTypesFigure() {
            try {
                
                this.validate_types_figure = validate(this.typesFigure, this.rules_typesFigure);
                if (!this.validate_types_figure.valid) throw 'Favor de validar los campos';

                let typesFigure = {
                    ...this.typesFigure,
                };

                typesFigure.id_number = (this.entity.consignment_note_types_figures.length + 1).toString();
                this.entity.consignment_note_types_figures.push(typesFigure);
                    this.typesFigure = new ConsignmentNoteTypesFigure(this.session);
                } catch (error) {
                    this.$toast.add(new ErrorToast(error));
                }
        },
        saveGoods() {
            try {
                
                this.validate_goods = validate(this.goods, this.rules_goods);
                if (!this.validate_goods.valid) throw 'Favor de validar los campos';

                let goods = {
                    ...this.goods,
                };

                if(this.entity.international_transport == '1' && this.goods.petition_number.length > 0)
                {
                    for (let i = 0; i < this.goods.petition_number.length; i++) {
                    if(this.goods.petition_number[i] == "")
                        throw "Favor de agregar el numero de pedimento #" + (i + 1);
                    }
                }

                goods.id_number = (this.entity.consignment_note_goods.length + 1).toString();
                this.entity.consignment_note_goods.push(goods);
                console.log(goods);
                    this.goods = new ConsignmentNoteGoods(this.session);
                } catch (error) {
                    this.$toast.add(new ErrorToast(error));
                }
        },
        actualizaSubRemolque() {
            try {
               
                this.validate_sub_trailer_update = validate(this.subTrailerUpdate, this.rules_subTrailer);
                if (!this.validate_sub_trailer_update.valid) throw 'Favor de validar los campos';

                let subTrailer = {
                    ...this.subTrailer,
                };

   
                //Actualizamos el index
                let index = this.entity.consignment_note_trailers.findIndex(x => x == subTrailer.id_number);
                this.entity.consignment_note_trailers[index] = subTrailer;
                this.subTrailerDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        actualizaSaveOriginLocation() {
            try {
               
                this.validate_origin_location_update = validate(this.originLocationUpdate, this.rules_originLocation);
                if (!this.validate_origin_location_update.valid) throw 'Favor de validar los campos';

                let originLocation = {
                    ...this.originLocation,
                };

   
                //Actualizamos el index
                let index = this.entity.consignment_note_origin_locations.findIndex(x => x == originLocation.id_number);
                this.entity.consignment_note_origin_locations[index] = originLocation;
                this.originLocationDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        actualizaSaveDestinyLocation() {
            try {
               
                this.validate_destiny_location_update = validate(this.destinyLocationUpdate, this.rules_destinyLocation);
                if (!this.validate_destiny_location_update.valid) throw 'Favor de validar los campos';

                let destinyLocation = {
                    ...this.destinyLocation,
                };

   
                //Actualizamos el index
                let index = this.entity.consignment_note_destiny_locations.findIndex(x => x == destinyLocation.id_number);
                this.entity.consignment_note_destiny_locations[index] = destinyLocation;
                this.destinyLocationDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
          actualizaSaveTypesFigure() {
            try {
               
                this.validate_types_figure_update = validate(this.typesFigureUpdate, this.rules_typesFigure);
                if (!this.validate_types_figure_update.valid) throw 'Favor de validar los campos';

                let typesFigure = {
                    ...this.typesFigure,
                };

   
                //Actualizamos el index
                let index = this.entity.consignment_note_types_figures.findIndex(x => x == typesFigure.id_number);
                this.entity.consignment_note_types_figures[index] = typesFigure;
                this.typesFigureDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        actualizaSaveGoods() {
            try {
               
                this.validate_goods_update = validate(this.goodsUpdate, this.rules_goods);
                if (!this.validate_goods_update.valid) throw 'Favor de validar los campos';

                let goods = {
                    ...this.goods,
                };
                
                if(this.entity.international_transport == '1' && this.goodsUpdate.petition_number.length > 0)
                {
                    for (let i = 0; i < this.goodsUpdate.petition_number.length; i++) {
                    if(this.goodsUpdate.petition_number[i] == "")
                        throw "Favor de agregar el numero de pedimento #" + (i + 1);
                    }
                }

   
                //Actualizamos el index
                let index = this.entity.consignment_note_goods.findIndex(x => x == goods.id_number);
                this.entity.consignment_note_goods[index] = goods;
                this.goodsDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        edit() {
        },
        confirmDeleteSubTrailer(subTrailer) {
            this.subTrailer = fillObject(this.subTrailer, {...subTrailer});
            this.deleteDialog = true;
        },
        confirmDeleteOrigin(originLocation) {
            this.originLocation = fillObject(this.originLocation, {...originLocation});
            this.deleteDialog = true;
        },
        confirmDeleteDestiny(destinyLocation) {
            this.destinyLocation = fillObject(this.destinyLocation, {...destinyLocation});
            this.deleteDialog = true;
        },
        confirmDeleteTypesFigure(typesFigure) {
            this.typesFigure = fillObject(this.typesFigure, {...typesFigure});
            this.deleteDialog = true;
        },
        confirmDeleteGoods(goods) {
            this.goods = fillObject(this.goods, {...goods});
            this.deleteDialog = true;
        },
        async deleteSubTrailer() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.subTrailer.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.subTrailer.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new ConsignmentNoteTrailer(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async deleteOriginLocation() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.originLocation.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.originLocation.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new ConsignmentNoteOriginLocation(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
         async deleteDestinyLocation() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.destinyLocation.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.destinyLocation.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new ConsignmentNoteDestinationLocation(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async deleteTypesFigure() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.typesFigure.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.typesFigure.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new ConsignmentNoteTypesFigure(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async deleteGoods() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.goods.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.goods.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new ConsignmentNoteGoods(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
            try {
                //Si tiene un id lo cargamos
                if (this.id) {
                   // this.entityinvoice.id = this.id;
                   // let retrieved = await this.entityinvoice.retrieve();
                   // fillObject(this.entityinvoice, retrieved);
                   // if (this.entityinvoice.date) this.entityinvoice.date = new Date(this.entityinvoice.date);
                }
                this.customers = await new Customer(this.session).all();
                this.consignments = await new ConsignmentNote(this.session).all();
                this.confvehicles = await new ConsignmentNoteVehicleControl(this.session).all();
                this.residencesFiscalOrigin = await new FiscalResidence(this.session).all();
                this.residencesFiscalDestiny = await new FiscalResidence(this.session).all();
                this.residencesFiscalFigure = await new FiscalResidence(this.session).all();
                this.figures = await new ConsignmentNoteFigure(this.session).all();
                
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
    },
};
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
